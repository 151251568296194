import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage';
import Loans from './components/Loans';


//import 'primereact/resources/themes/md-light-indigo/theme.css';
//import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css'; // core
import 'primeicons/primeicons.css';

import './assets/styles.css';


import {PrimeReactProvider} from 'primereact/api';

function App() {
    return (
        <PrimeReactProvider>
            <Router>
                <div className="app-container">
                    <Header/>

                    <main>
                        <Routes>
                            <Route path="/loans" element={<Loans/>}/>
                            <Route path="/" element={<LandingPage/>}/>
                            <Route path="*" element={<LandingPage/>}/>
                        </Routes>
                    </main>

                    <Footer/>
                </div>
            </Router>
        </PrimeReactProvider>
    );
}

export default App;
